var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditLayout",
    {
      attrs: {
        "supports-photo": "",
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        photo: _vm.photo
      },
      on: {
        submit: function($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        }
      }
    },
    [
      _c("div", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("BaseInput", {
              staticClass: "subtitle col-sm-6",
              attrs: {
                label: _vm.$t("nickname"),
                placeholder: _vm.$t("enterNickname")
              },
              model: {
                value: _vm.item.nickname,
                callback: function($$v) {
                  _vm.$set(_vm.item, "nickname", $$v)
                },
                expression: "item.nickname"
              }
            }),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$tc("category")))]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.category_id,
                      expression: "item.category_id"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.item,
                        "category_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.categories, function(category) {
                  return _c(
                    "option",
                    {
                      key: category.id + "category",
                      domProps: { value: category.id }
                    },
                    [_vm._v(" " + _vm._s(category.name) + " ")]
                  )
                }),
                0
              )
            ])
          ],
          1
        )
      ]),
      _c(
        "div",
        { attrs: { slot: "middle" }, slot: "middle" },
        [
          _c("label", [_vm._v(_vm._s(_vm.$t("gallery")))]),
          _c(
            "div",
            { staticClass: "gallery" },
            [
              !_vm.gallery.length
                ? _c(
                    "div",
                    _vm._l(6, function(index) {
                      return _c("BaseIcon", {
                        key: index,
                        staticClass: "placeholderIcons",
                        attrs: {
                          "icons-group": "fas",
                          icon: "fa-image",
                          size: "xxl"
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "Draggable",
                {
                  staticClass: "galleryInner",
                  on: { change: _vm.updateGalleryModel },
                  model: {
                    value: _vm.gallery,
                    callback: function($$v) {
                      _vm.gallery = $$v
                    },
                    expression: "gallery"
                  }
                },
                _vm._l(_vm.gallery, function(galleryItem) {
                  return _c(
                    "div",
                    {
                      key: "gallery" + galleryItem.id,
                      staticClass: "galleryItem"
                    },
                    [
                      _c("BaseImage", {
                        attrs: { source: galleryItem, size: "thumb" }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "BaseButton",
                {
                  attrs: { type: "green", gradient: "" },
                  on: {
                    click: function($event) {
                      _vm.isGalleryModalOpened = true
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("updateGallery")) + " ")]
              )
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                show: _vm.isGalleryModalOpened,
                centered: true,
                "show-close": true
              },
              on: {
                "update:show": function($event) {
                  _vm.isGalleryModalOpened = $event
                }
              }
            },
            [
              _vm.isGalleryModalOpened
                ? _c("LibraryList", {
                    staticClass: "library",
                    attrs: {
                      limit: 40,
                      "select-items": "multiple",
                      "preselected-items": _vm.gallery,
                      "fixed-type": "photos",
                      "grid-item-size": "100"
                    },
                    on: { updateSelected: _vm.updateGallery }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "libraryButton text-right" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { type: "green", gradient: "" },
                      on: { click: _vm.confirmNewGallery }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "dates" }, [
            _c("label", [_vm._v(_vm._s(_vm.$tc("date", 2)))]),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("releaseDate") + " " + _vm.$t("worldwide"),
                    type: "date"
                  },
                  model: {
                    value: _vm.item.release_date_worldwide,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "release_date_worldwide", $$v)
                    },
                    expression: "item.release_date_worldwide"
                  }
                }),
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label:
                      _vm.$t("discontinuedDate") + " " + _vm.$t("worldwide"),
                    type: "date"
                  },
                  model: {
                    value: _vm.item.discontinue_date_worldwide,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "discontinue_date_worldwide", $$v)
                    },
                    expression: "item.discontinue_date_worldwide"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("releaseDate") + " Australia",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.release_date_australia,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "release_date_australia", $$v)
                    },
                    expression: "item.release_date_australia"
                  }
                }),
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("discontinuedDate") + " Australia",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.discontinue_date_australia,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "discontinue_date_australia", $$v)
                    },
                    expression: "item.discontinue_date_australia"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: { label: _vm.$t("releaseDate") + " EU", type: "date" },
                  model: {
                    value: _vm.item.release_date_eu,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "release_date_eu", $$v)
                    },
                    expression: "item.release_date_eu"
                  }
                }),
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("discontinuedDate") + " EU",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.discontinue_date_eu,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "discontinue_date_eu", $$v)
                    },
                    expression: "item.discontinue_date_eu"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("releaseDate") + " Japan",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.release_date_jp,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "release_date_jp", $$v)
                    },
                    expression: "item.release_date_jp"
                  }
                }),
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("discontinuedDate") + " Japan",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.discontinue_date_jp,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "discontinue_date_jp", $$v)
                    },
                    expression: "item.discontinue_date_jp"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("releaseDate") + " N. America",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.release_date_namerica,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "release_date_namerica", $$v)
                    },
                    expression: "item.release_date_namerica"
                  }
                }),
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("discontinuedDate") + " N. America",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.discontinue_date_namerica,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "discontinue_date_namerica", $$v)
                    },
                    expression: "item.discontinue_date_namerica"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("releaseDate") + " USA",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.release_date_usa,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "release_date_usa", $$v)
                    },
                    expression: "item.release_date_usa"
                  }
                }),
                _c("BaseInput", {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: _vm.$t("discontinuedDate") + " USA",
                    type: "date"
                  },
                  model: {
                    value: _vm.item.discontinue_date_usa,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "discontinue_date_usa", $$v)
                    },
                    expression: "item.discontinue_date_usa"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
        [
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$t("predecessor")))]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.previous_console,
                    expression: "item.previous_console"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "previous_console",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v(" " + _vm._s(_vm.$t("selectAConsole")) + " ")
                ]),
                _vm._l(_vm.consoles, function(consolo) {
                  return _c(
                    "option",
                    {
                      key: consolo.id + "consolo-previous",
                      domProps: { value: consolo.id }
                    },
                    [_vm._v(" " + _vm._s(consolo.name) + " ")]
                  )
                })
              ],
              2
            )
          ]),
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$t("successor")))]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.next_console,
                    expression: "item.next_console"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "next_console",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v(" " + _vm._s(_vm.$t("selectAConsole")) + " ")
                ]),
                _vm._l(_vm.consoles, function(consolo) {
                  return _c(
                    "option",
                    {
                      key: consolo.id + "consolo-next",
                      domProps: { value: consolo.id }
                    },
                    [_vm._v(" " + _vm._s(consolo.name) + " ")]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "div",
            [
              _c("BaseMultiSelect", {
                attrs: {
                  label: _vm.$tc("brand", 2),
                  items: _vm.brands,
                  "pre-selected-items": _vm.item.brand
                },
                on: { updateSelected: _vm.updateSelectedBrands }
              })
            ],
            1
          ),
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$tc("generation")))]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.generation_id,
                    expression: "item.generation_id"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "generation_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.generations, function(generation) {
                return _c(
                  "option",
                  {
                    key: generation.id + "generation",
                    domProps: { value: generation.id }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(generation.name) +
                        " " +
                        _vm._s(_vm.$tc("generation")) +
                        " "
                    )
                  ]
                )
              }),
              0
            )
          ]),
          _c(
            "div",
            [
              _c("BaseMultiSelect", {
                attrs: {
                  label: _vm.$tc("medium", 2),
                  items: _vm.media,
                  "pre-selected-items": _vm.item.media
                },
                on: { updateSelected: _vm.updateSelectedMedia }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseMultiSelect", {
                attrs: {
                  label: _vm.$tc("accessory", 2),
                  items: _vm.accessories,
                  "pre-selected-items": _vm.item.related_accessories
                },
                on: { updateSelected: _vm.updateSelectedAccessories }
              })
            ],
            1
          ),
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$tc("bit", 2)))]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.bit_id,
                    expression: "item.bit_id"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "bit_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v(" " + _vm._s(_vm.$t("selectConsoleBits")) + " ")
                ]),
                _vm._l(_vm.bits, function(bit) {
                  return _c(
                    "option",
                    { key: bit.id + "bits", domProps: { value: bit.id } },
                    [_vm._v(" " + _vm._s(bit.name) + " ")]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "div",
            [
              _c("BaseMultiSelect", {
                attrs: {
                  label: _vm.$tc("color", 2),
                  items: _vm.colors,
                  "pre-selected-items": _vm.item.colors
                },
                on: { updateSelected: _vm.updateSelectedColors }
              })
            ],
            1
          ),
          _c("div", [
            _c("label", [_vm._v(_vm._s(_vm.$t("colorPalette")))]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.palette_id,
                    expression: "item.palette_id"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "palette_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v(" " + _vm._s(_vm.$t("selectColorPalette")) + " ")
                ]),
                _vm._l(_vm.palettes, function(palette) {
                  return _c(
                    "option",
                    {
                      key: palette.id + "palettes",
                      domProps: { value: palette.id }
                    },
                    [_vm._v(" " + _vm._s(palette.name) + " ")]
                  )
                })
              ],
              2
            )
          ]),
          _c("div", [
            _c("label", [_vm._v("PDF")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.pdf_id,
                    expression: "item.pdf_id"
                  }
                ],
                staticClass: "form-control",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.item,
                      "pdf_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v(" " + _vm._s(_vm.$t("select")) + " PDF ")
                ]),
                _vm._l(_vm.pdfList, function(pdf) {
                  return _c(
                    "option",
                    { key: pdf.id + "pdf", domProps: { value: pdf.id } },
                    [_vm._v(" " + _vm._s(pdf.photo) + " ")]
                  )
                })
              ],
              2
            )
          ]),
          _c("BaseInput", {
            attrs: {
              label: _vm.$tc("size", 2),
              placeholder: _vm.$tc("size", 2)
            },
            model: {
              value: _vm.item.sizes,
              callback: function($$v) {
                _vm.$set(_vm.item, "sizes", $$v)
              },
              expression: "item.sizes"
            }
          }),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("BaseInput", {
                staticClass: "col-sm-6",
                attrs: {
                  label: _vm.$t("totalGames"),
                  placeholder: _vm.$t("totalGames")
                },
                model: {
                  value: _vm.item.games_number,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "games_number", $$v)
                  },
                  expression: "item.games_number"
                }
              }),
              _c("BaseInput", {
                staticClass: "col-sm-6",
                attrs: {
                  label: _vm.$t("initialPrice"),
                  placeholder: _vm.$t("initialPrice")
                },
                model: {
                  value: _vm.item.initial_price,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "initial_price", $$v)
                  },
                  expression: "item.initial_price"
                }
              })
            ],
            1
          ),
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("unitsSold"),
              type: "number",
              placeholder: _vm.$t("unitsSold")
            },
            model: {
              value: _vm.item.units_sold,
              callback: function($$v) {
                _vm.$set(_vm.item, "units_sold", $$v)
              },
              expression: "item.units_sold"
            }
          }),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "BaseInput",
                {
                  staticClass: "col-sm-4 col-6",
                  attrs: {
                    label: _vm.$t("educational"),
                    "is-slider-checkbox": ""
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.educational,
                        expression: "item.educational"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "checkbox",
                      "true-value": "1",
                      "false-value": "0"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.item.educational)
                        ? _vm._i(_vm.item.educational, null) > -1
                        : _vm._q(_vm.item.educational, "1")
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.item.educational,
                          $$el = $event.target,
                          $$c = $$el.checked ? "1" : "0"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.item,
                                "educational",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.item,
                                "educational",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.item, "educational", $$c)
                        }
                      }
                    }
                  })
                ]
              ),
              _c(
                "BaseInput",
                {
                  staticClass: "col-sm-4 col-6",
                  attrs: { label: _vm.$t("retro"), "is-slider-checkbox": "" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.retro,
                        expression: "item.retro"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "checkbox",
                      "true-value": "1",
                      "false-value": "0"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.item.retro)
                        ? _vm._i(_vm.item.retro, null) > -1
                        : _vm._q(_vm.item.retro, "1")
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.item.retro,
                          $$el = $event.target,
                          $$c = $$el.checked ? "1" : "0"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.item, "retro", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.item,
                                "retro",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.item, "retro", $$c)
                        }
                      }
                    }
                  })
                ]
              ),
              _c(
                "BaseInput",
                {
                  staticClass: "col-sm-4 col-6",
                  attrs: {
                    label: _vm.$t("unreleased"),
                    "is-slider-checkbox": ""
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.unreleased,
                        expression: "item.unreleased"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "checkbox",
                      "true-value": "1",
                      "false-value": "0"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.item.unreleased)
                        ? _vm._i(_vm.item.unreleased, null) > -1
                        : _vm._q(_vm.item.unreleased, "1")
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.item.unreleased,
                          $$el = $event.target,
                          $$c = $$el.checked ? "1" : "0"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.item,
                                "unreleased",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.item,
                                "unreleased",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.item, "unreleased", $$c)
                        }
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { attrs: { slot: "sidebar-bottom" }, slot: "sidebar-bottom" }, [
        _c("div", { staticClass: "metaIndexling form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("metaIndexing")))]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.seo_indexing,
                  expression: "item.seo_indexing"
                }
              ],
              staticClass: "form-control",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.item,
                    "seo_indexing",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(" " + _vm._s(_vm.$t("indexing")) + " ")
              ]),
              _vm._l(_vm.indexing, function(index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: index } },
                  [_vm._v(" " + _vm._s(index) + " ")]
                )
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }